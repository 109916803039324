
import { defineComponent } from "vue";
import { ApiUrls } from "@/consts/ApiUrls";
import CalendarHeader from "@/components/sales/CalendarHeader.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import ValidateError from "@/components/ValidateError.vue";
import { PageService } from "@/services/PageService";
import {
  sendSaleApi,
  indexSaleApi,
  salesDownloadCsvUrlApi,
  salesDownloadInvoicePdfUrlApi,
} from "@/api/sales";

export default defineComponent({
  name: "SaleDate",
  components: {
    CalendarHeader,
    ErrorMessage,
    ValidateError,
  },
  data: () => ({
    isShow: false,
    message: "",
    sales: [] as any[],
    sendSaleId: 0,
    saleIds: [] as number[],
    sendNumbers: [] as string[],
    pageService: new PageService(),
    predictPrices: 0,
    confirmedPrices: 0,
  }),
  mounted: async function () {
    await this.searchSales(this.$route.params.date);
    this.isShow = true;
  },
  beforeRouteUpdate: async function (to, from, next): Promise<void> {
    await this.searchSales(to.params.date);
    this.isShow = true;
    next();
  },
  methods: {
    commonError: function (result: any = null): void {
      if (result.status === 422) {
        this.validateErrors = result.data;
      }
      if (result.message !== "") {
        this.message = result.message;
      }
    },
    resetMessage: function () {
      this.message = "";
    },
    setSendNumbersInitialData(): void {},
    sendSale: async function () {
      const result = await sendSaleApi(
        this.sendSaleId,
        this.sendNumbers[this.sendSaleId]
      );
      if (!result.success) {
        this.commonError(result);
        return;
      }
      for (const sale of this.sales) {
        if (sale.id === this.sendSaleId) {
          sale.is_send = 1;
        }
      }
      window.location.reload();
    },
    downloadInvoicePdf: async function () {
      if (this.saleIds.length === 0) {
        alert("チェックを入れてください");
        return;
      }

      const result = await salesDownloadInvoicePdfUrlApi(this.saleIds);
      if (!result.success) {
        this.commonError(result);
        return;
      }

      const filenames = result.data.filenames;
      for (const filename of filenames) {
        const url = `${process.env.VUE_APP_API_URL}files/${filename}`;
        window.open(url, "_blank");
      }
    },
    downloadCsv: async function (event: any) {
      event.preventDefault();
      if (this.saleIds.length === 0) {
        alert("チェックを入れてください");
        return;
      }

      const result = await salesDownloadCsvUrlApi(this.saleIds);
      if (!result.success) {
        this.commonError(result);
        return;
      }

      const filename = result.data.filename;
      const url = `${process.env.VUE_APP_API_URL}files/${filename}`;
      window.open(url, "_blank");
    },
    searchSales: async function (date: string) {
      const result = await indexSaleApi({
        date: date,
        receive_status: "receive", // 受注可能なもの
      });
      if (!result.success) {
        this.commonError(result);
        return;
      }
      if (Array.isArray(result.data)) {
        this.sales = result.data;

        if (this.sales.length === 0) {
          return;
        }
        for (const sale of this.sales) {
          this.sendNumbers[sale.id] = sale.send_number;
          this.predictPrices += sale.total_price; //売上予想
          if (sale.is_send) {
            this.confirmedPrices += sale.total_price; // 確定売上
          }
        }
      }
    },
    sendModal: function (saleId: number) {
      this.sendSaleId = saleId;
    },
    toNextPage: function () {
      this.isShow = false;
      this.sales = [];
      this.predictPrices = 0;
      this.$router.push({
        name: "SaleDate",
        params: {
          date: this.pageService.dateFormmat3(this.$route.params.date, -1),
        },
      });
    },
    toPreviousPage: function () {
      this.isShow = false;
      this.sales = [];
      this.confirmedPrices = 0;

      this.$router.push({
        name: "SaleDate",
        params: {
          date: this.pageService.dateFormmat3(this.$route.params.date, +1),
        },
      });
    },
  },
});
